import { Component, OnInit, Input } from '@angular/core';
import { Attribute } from '@angular/compiler';

@Component({
  selector: 'app-quotecard',
  templateUrl: './quotecard.component.html',
  styleUrls: ['./quotecard.component.scss']
})
export class QuotecardComponent implements OnInit {
  @Input() quote: string;
  @Input() attrib: string;
  @Input() category: string;
  constructor() { }

  ngOnInit() {

  }

}
