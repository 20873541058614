
/*
// Live production vals 
export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyB80rqOfBFpfOJzQerrBl6HIwZbQkr0WFk',
    authDomain: 'simplevisionboard.firebaseapp.com',
    databaseURL: 'https://simplevisionboard.firebaseio.com',
    projectId: 'simplevisionboard',
    storageBucket: 'simplevisionboard.appspot.com',
    messagingSenderId: '96025394406'
  },
  functionsURL: 'https://us-central1-stripe-elements.cloudfunctions.net',
  stripePublishable: 'pk_live_ojNqMtevWOSeLRI5I4t3OZjU'
};
*/


// @TODO: update with production values above
export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyDRqkED4D2r96s5eIcDbKoUQEPayMbAq7Y',
    authDomain: 'simplevisionboarddev.firebaseapp.com',
    databaseURL: 'https://simplevisionboarddev.firebaseio.com',
    projectId: 'simplevisionboarddev',
    storageBucket: 'simplevisionboarddev.appspot.com',
    messagingSenderId: '510038744280'
  },

  // http://localhost:5000
  functionsURL: ' http://localhost:5000/stripe-elements/us-central1',
  // functionsURL: 'https://us-central1-stripe-elements.cloudfunctions.net',

  stripePublishable: 'pk_test_m3a5moXVKgThpdfwzKILvnbG'
};
